import styled from 'styled-components';

export const ConfirmWindowWrapper = styled.div`
  width: 600px;
  display: grid;
  grid-template-rows: 32px 1fr 60px;
  background: white;
  border-radius: 40px;
  border: 1px solid #ebe6f8;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
`;