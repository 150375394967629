import ActionTypes from "../constants/actionTypes";

export const initData = (payload) => ({ type: ActionTypes.INIT_DATA, payload });
export const onLogInAction = (payload) => ({
  type: ActionTypes.ON_LOG_IN_ACTION,
  payload,
});
export const changeTableMode = (payload) => ({
  type: ActionTypes.CHANGE_TABLE_MODE,
  payload,
});
export const openModalWindow = (payload) => ({
  type: ActionTypes.OPEN_MODAL_WINDOW,
  payload,
});
export const onConfirmAction = () => ({ type: ActionTypes.ON_CONFIRM_ACTION });
export const setTableModeStore = (payload) => ({
  type: ActionTypes.SET_TABLE_MODE_STORE,
  payload,
});
export const changeTableSubMode = (payload) => ({
  type: ActionTypes.CHANGE_TABLE_SUB_MODE,
  payload,
});
export const onCloseModalWindow = () => ({
  type: ActionTypes.ON_CLOSE_MODAL_WINDOW,
});
export const setTableItemsStore = (payload) => ({
  type: ActionTypes.SET_TABLE_ITEMS_STORE,
  payload,
});
export const setTableSubModeStore = (payload) => ({
  type: ActionTypes.SET_TABLE_SUB_MODE_STORE,
  payload,
});
export const setAddParseInputValue = (payload) => ({
  type: ActionTypes.SET_ADD_PARSE_INPUT_VALUE,
  payload,
});
export const setTranslatesDataStore = (payload) => ({
  type: ActionTypes.SET_TRANSLATES_DATA_STORE,
  payload,
});
export const changeModalWindowState = (payload) => ({
  type: ActionTypes.CHANGE_MODAL_WINDOW_STATE,
  payload,
});
export const setGeoDataSelectedPage = (payload) => ({
  type: ActionTypes.SET_GEO_DATA_SELECTED_PAGE,
  payload,
});
export const setFeeFilteredListStore = (payload) => ({
  type: ActionTypes.SET_FEE_FILTERED_LIST_STORE,
  payload,
});
export const setTranslateSelectedKey = (payload) => ({
  type: ActionTypes.SET_TRANSLATE_SELECTED_KEY,
  payload,
});
export const setGeoDataTotalPageStore = (payload) => ({
  type: ActionTypes.SET_GEO_DATA_TOTAL_PAGE_STORE,
  payload,
});
export const setAdminPhrasesDataStore = (payload) => ({
  type: ActionTypes.SET_ADMIN_PHRASES_DATA_STORE,
  payload,
});
export const setAddLanguageInputsValue = (payload) => ({
  type: ActionTypes.SET_ADD_LANGUAGE_INPUTS_VALUE,
  payload,
});
export const setIsAuthInputsErrorStore = (payload) => ({
  type: ActionTypes.SET_IS_AUTH_INPUTS_ERROR_STORE,
  payload,
});
export const setPlatformLanguagesStore = (payload) => ({
  type: ActionTypes.SET_PLATFORM_LANGUAGES_STORE,
  payload,
});
export const sendUserInfoResponseStore = (payload) => ({
  type: ActionTypes.SEND_USER_INFO_RESPONSE_STORE,
  payload,
});
export const setFilteredTableItemsStore = (payload) => ({
  type: ActionTypes.SET_FILTERED_TABLE_ITEMS_STORE,
  payload,
});
export const setStringsSearchInputValue = (payload) => ({
  type: ActionTypes.SET_STRINGS_SEARCH_INPUT_VALUE,
  payload,
});
export const setGeoDataSelectedPageStore = (payload) => ({
  type: ActionTypes.SET_GEO_DATA_SELECTED_PAGE_STORE,
  payload,
});
export const setIsEmptyStringsFilterMode = (payload) => ({
  type: ActionTypes.SET_IS_EMPTY_STRINGS_FILTER_MODE,
  payload,
});
export const setStringsSearchInputValueStore = (payload) => ({
  type: ActionTypes.SET_STRINGS_SEARCH_INPUT_VALUE_STORE,
  payload,
});
export const setIsEmptyStringsFilterModeStore = (payload) => ({
  type: ActionTypes.SET_IS_EMPTY_STRINGS_FILTER_MODE_STORE,
  payload,
});
export const setTranslateSelectedKeyInputValue = (payload) => ({
  type: ActionTypes.SET_TRANSLATE_SELECTED_KEY_INPUT_VALUE,
  payload,
});
