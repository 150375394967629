import React from 'react';
import {
    Input,
    TextValue,
    WrapperMain,
} from './styledComponents';

const AddNewPhraseMainConfirm = props => {
    const {
        addParseInputValue,
        setAddParseInputValue,
    } = props;

    return (
        <WrapperMain>
            <TextValue>Enter name new key: </TextValue>
            <Input placeholder={'Enter key'} value={addParseInputValue} onChange={event => setAddParseInputValue({
                addNewPhraseInputValue: event.currentTarget.value,
            })}/>
        </WrapperMain>
    );
};

export default AddNewPhraseMainConfirm;