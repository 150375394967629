import styled from 'styled-components';

export const WrapperFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: end;
`;

const Button = styled.div`
  height: 50px;
  width: 140px;
  font-weight: bold;
  color: white;
  font-size: 24px;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    box-shadow: 10px 5px 5px grey;
    transform: scale(110%);
    transition: box-shadow, transform .15s linear;
  }
`;

export const ConfirmButton = styled(Button)`
    background: #42c0dc;
`

export const CloseButton = styled(Button)`
    background: pink;
`
