import React, { useState, useEffect } from 'react';
import {
    Search,
    ButtonSearch,
    NumbersWrapper,
    PaginationWrapper, PaginationItem, SearchWrapper,
} from './styledComponents';
import { getArrayPage } from "./logic";

const Pagination = props => {
    const {
        totalPage,
        selectedPage,
        setSelectedPage,
    } = props;
    const [value, setValue] = useState(1);
    const numberTotalPage = Number(totalPage);
    const numberSelectedPage = Number(selectedPage);
    const numberInputValue = Number(value);

    useEffect(() => {
        setValue(selectedPage);
    }, [selectedPage])

    useEffect(() => {
        setValue(selectedPage);
    }, [selectedPage])

    const handleOnSelectedPage = event => {
        if (event.currentTarget.accessKey === '< prev') {
            if (numberSelectedPage > 1) {
                setSelectedPage(numberSelectedPage - 1)
            } else {
                return;
            }
        } else if (event.currentTarget.accessKey === 'next >') {
            if (numberSelectedPage < numberTotalPage) {
                setSelectedPage(numberSelectedPage + 1)
            } else {
                return;
            }
        } else if (Number(event.currentTarget.accessKey)) {
            setSelectedPage(Number(event.currentTarget.accessKey))
        } else if ((event.currentTarget.accessKey === 'button' || event.which === 13) && numberInputValue > 0 && numberInputValue <= totalPage) {
            event.preventDefault();
            setSelectedPage(numberInputValue)
        }
        return;
    }

    const pagesList = getArrayPage(numberTotalPage, numberSelectedPage);

    return (
        <PaginationWrapper>
            <NumbersWrapper>
                {
                    pagesList.map(item =>
                        <PaginationItem
                            key={item.key}
                            onClick={handleOnSelectedPage}
                            accessKey={item.value}
                            isSelectedPage={numberSelectedPage === Number(item.value)}
                            isDisabledHover={'...' === item.value
                                || item.value === '< prev' && numberSelectedPage === 1
                                || item.value === 'next >' && numberSelectedPage === numberTotalPage
                            }
                            isDisabledPadding={'...' === item.value}
                        >
                            {item.value}
                        </PaginationItem>
                    )
                }
            </NumbersWrapper>
            <SearchWrapper>
                <Search
                    type={'number'}
                    value={numberInputValue}
                    onChange={event => setValue(Number(event.target.value))}
                    onKeyPress={handleOnSelectedPage}
                />
                <ButtonSearch
                    onClick={handleOnSelectedPage}
                    accessKey={'button'}
                > Search Page </ButtonSearch>
            </SearchWrapper>
        </PaginationWrapper>
    );
};

export default React.memo(Pagination);