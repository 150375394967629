export const getHeaderTableList = (languagesUIList, condition) => {
    const newArray = [];

    for (let i = 0; i < languagesUIList.length; i++) {
        if (condition && i === 2) {
            newArray.push('VAT');
        }

        newArray.push(languagesUIList[i]);
    }

    return newArray;
}
