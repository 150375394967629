export function parseJSON(string) {
    try {
        return JSON.parse(string);
    } catch (e) {
        return false;
    }
}

export function stringifyJSON(data) {
    try {
        return JSON.stringify(data);
    } catch (e) {
        return false;
    }
}

export function parseJSONByDB(string) {
    try {
        return string.data;
    } catch (e) {
        return false;
    }
}