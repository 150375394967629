import React, { useRef } from "react";
import { WrapperMainTable } from "./styledComponents";
import Item from "./components/item/Item";
import VirtualList from "../../../../../customComponents/virtualList/VirtualList";

function getItem(item, openModalWindow) {
  return <Item key={item.ID} item={item} openModalWindow={openModalWindow} />;
}

const MainTable = (props) => {
  const listRef = useRef(null);
  const { tableItems, openModalWindow } = props;

  return (
    <WrapperMainTable ref={listRef}>
      {
        <VirtualList
          itemsList={tableItems}
          itemHeight={80}
          renderItem={(item) => getItem(item, openModalWindow)}
          containerRef={listRef}
        />
      }
    </WrapperMainTable>
  );
};

export default React.memo(MainTable);
