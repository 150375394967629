import styled from "styled-components";

export const ItemWrapper = styled.div`
  min-height: 80px;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 200px ${props => props.griadColumsRepeatCount
                                  ? `repeat(${props.griadColumsRepeatCount}, 250px) `
                                  : ''
                        
                          }
                          40px;

  div:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  div:nth-child(2) {
    position: sticky;
    left: 40px;
    z-index: 1;
  }
  
  &:nth-child(odd) {
    div  {
      background: #f8f5fc;
    }
  }
`;