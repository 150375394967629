import Component from './ModalManager'
import { connect } from 'react-redux';
import * as selectors from '../../selectors/selectors';
import * as actions from '../../actions/actions';

export const mapStateToProps = state => ({
    isShow: selectors.getModalIsShowState(state),
    context: selectors.getModalContext(state),
})

export const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(actions.onCloseModalWindow())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component)