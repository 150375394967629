import React from 'react';
import {
    TextValue,
    CodeInput,
    TitleInput,
    WrapperMain,
} from './styledComponents';

const AddLanguageMainConfirm = ({ inputs, setInputsValue }) => {
    const {
        code,
        title,
    } = inputs;

    return (
        <WrapperMain>
            <TextValue>Enter Code: </TextValue>
            <CodeInput placeholder={'Enter Code'} value={code} onChange={event => setInputsValue({
                ...inputs,
                code: event.currentTarget.value,
            })}/>
            <TextValue>Enter Title: </TextValue>
            <TitleInput placeholder={'Enter Title'} value={title} onChange={event => setInputsValue({
                ...inputs,
                title: event.currentTarget.value,
            })}/>
        </WrapperMain>
    );
};

export default React.memo(AddLanguageMainConfirm);