import React, { useEffect } from "react";
import { Input, TextValue, WrapperMain } from "./styledComponents";

const ChangeStringValue = (props) => {
  const { context, changeTranslateState, setTranslateSelectedKeyInputValue } =
    props;

  useEffect(() => {
    setTranslateSelectedKeyInputValue(context.text);
  }, []);

  const splitValue = changeTranslateState.selectedKey.split(" ");
  const langValue = splitValue[splitValue.length - 2];
  splitValue.length = splitValue.length - 2;
  const keyValue = context?.feeData?.name ?? splitValue.join(" ");

  return (
    <WrapperMain>
      <TextValue>
        Enter new value for <span>{keyValue}</span> on <span>{langValue}</span>
        {context.isVAT || context?.feeData ? null : ` language: `}
      </TextValue>

      <Input
        value={changeTranslateState.inputValue}
        onChange={(event) =>
          setTranslateSelectedKeyInputValue(event.currentTarget.value)
        }
        placeholder={"Enter key"}
      />
    </WrapperMain>
  );
};

export default React.memo(ChangeStringValue);
