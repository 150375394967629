import styled from 'styled-components';

export const WrapperHeaderTable = styled.div`    
  display: grid;
  grid-template-columns: 80px 200px ${props => props.griadColumsRepeatCount
                                            ? `repeat(${props.griadColumsRepeatCount}, 250px) `
                                            : ''
                          }
                          40px;
  border-bottom: 1px solid #e1e4e5;
  
  div:first-child {
    position: sticky;
    left: 0;
  }
  
  div:nth-child(2) {
    position: sticky;
    left: 80px;
  }
`;