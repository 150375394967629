import Component from './LogIn';
import { connect } from 'react-redux';
import * as selectors from '../../../../selectors/selectors';
import * as actions from '../../../../actions/actions';

export const mapStateToProps = state => ({
    isAuthInputsError: selectors.getIsAuthInputsError(state),
});

export const mapDispatchToProps = dispatch => ({
    onLogInAction: payload => dispatch(actions.onLogInAction(payload)),
    setIsAuthInputsError: payload => dispatch(actions.setIsAuthInputsErrorStore(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);