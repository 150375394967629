import Component from './ConfirmWindow';
import { connect } from 'react-redux';
import * as selectors from '../../../../selectors/selectors';
import * as actions from '../../../../actions/actions';

export const mapStateToProps = state => ({
    context: selectors.getModalContext(state),
});

export const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(actions.onCloseModalWindow()),
    onConfirm: () => dispatch(actions.onConfirmAction()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
