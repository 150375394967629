import React from 'react';
import {
    Wrapper,
    CloseButton,
    ConfirmTitle,
} from './styledComponents';

const HeaderConfirmWindow = props => {
    const {
        onClose,
        context,
    } = props;

    return (
        <Wrapper>
            <ConfirmTitle>
                {
                    context.subType === 'addLanguage'
                        ? 'Create new language'
                        : context.subType === 'changeStringValue'
                            ? 'Change translate window'
                        : context.subType === 'deletePhrase'
                            ? 'Delete phrase'
                            : 'Create new lang tag key'
                }
            </ConfirmTitle>
            <CloseButton onClick={onClose}/>
        </Wrapper>
    );
};

export default HeaderConfirmWindow;