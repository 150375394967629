import Component from './AddNewPhraseMainConfirm';
import { connect } from 'react-redux';
import * as selectors from '../../../../../../../selectors/selectors';
import * as actions from '../../../../../../../actions/actions';

export const mapStateToProps = state => ({
    addParseInputValue: selectors.getAddParseInputValue(state),
});

export const mapDispatchToProps = dispatch => ({
    setAddParseInputValue: payload => dispatch(actions.setAddParseInputValue(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);