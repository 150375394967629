import Modal from 'react-modal';
import React from 'react';
import ConfirmWindow from './components/confirmWindow';

let customStyles = {
    overlay: {
        right: 0,
        bottom: 0,
        zIndex: 200,
        position: 'static',
        backgroundColor: 'rgba(0,0,0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        border: 'none',
        zIndex: 199,
        padding: '0',
        overflowY: 'hidden',
        transform: 'translate(-50%, -50%)',
        boxShadow: 'rgb(66 192 220 / 50%) 0px 0rem 9rem 0px',
        background: 'none',
        marginRight: '-50%',
        borderRadius: '40px',
    },
};

const getContent = props => {
    const { context } = props;

    switch (context?.type) {
        case 'confirm':
            return <ConfirmWindow />
        default:
            return null;
    }
}

const ModalManager = (props) => {
    const {
        isShow,
        onClose,
    } = props;

    return (
        <Modal
            style={customStyles}
            isOpen={isShow}
            ariaHideApp={false}
            onRequestClose={onClose}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            {getContent(props)}
        </Modal>
    );
};

export default React.memo(ModalManager);