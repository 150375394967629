import React from 'react';
import { Wrapper } from './styledComponents';

const Header = () => {
    return (
        <Wrapper>
            <Wrapper.logo/>
            <Wrapper.title>Admin Ice Dating</Wrapper.title>
        </Wrapper>
    );
};

export default Header;