import Component from './RootComponent';
import { connect } from 'react-redux';
import * as selectors from '../../selectors/selectors';
import * as actions from '../../actions/actions';

export const mapStateToProps = state => ({
    theme: selectors.getTheme(state),
    userType: selectors.getAuthUserType(state),
});

export const mapDispatchToProps = dispatch => ({
    initData: () => dispatch(actions.initData()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);