import React from 'react';
import { ConfirmWindowWrapper } from './styledComponents';
import HeaderConfirmWindow from './headerConfirmWindow/HeaderConfirmWindow';
import MainConfirmWindow from './mainConfirmWindow/MainConfirmWindow';
import FooterConfirmWindow from './footerConfirmWindow/FooterConfirmWindow';

const ConfirmWindow = props => {
    const {
        onClose,
        context,
        onConfirm,
    } = props;

    return (
        <ConfirmWindowWrapper>
            <HeaderConfirmWindow
                onClose={onClose}
                context={context}
            />
            <MainConfirmWindow
                context={context}
            />
            <FooterConfirmWindow
                onClose={onClose}
                context={context}
                onConfirm={onConfirm}
            />
        </ConfirmWindowWrapper>
    );
};

export default React.memo(ConfirmWindow);
