import styled from 'styled-components';

export const WrapperMain = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
`;

export const Input = styled.input`
  border: 1px solid #d9dddd;
  border-radius: 14px;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  font-weight: 500;
  height: 45px;
  line-height: 25px;
  padding: 10px;
  width: 270px;
  outline: none;
  justify-self: center;
`;

export const TextValue = styled.div`
    align-self: center;
`;