import React from 'react';
import AddLanguageMainConfirm from './components/addLanguageMainConfirm';
import AddNewPhraseMainConfirm from './components/addNewPhraseMainConfirm';
import ChangeStringValue from './components/changeStringValue';
import DeleteStringValue from './components/deleteStringValue';

const MainConfirmWindow = props => {
    const { context } = props;
    switch (context?.subType) {
        case 'addLanguage':
            return (
                <AddLanguageMainConfirm/>
            );
        case 'addNewPhrase':
            return (
                <AddNewPhraseMainConfirm/>
            );
        case 'changeStringValue':
            return (
                <ChangeStringValue context={context}/>
            );
        case 'deletePhrase':
            return (
                <DeleteStringValue context={context}/>
            );
        default:
            return null;
    }
};

export default MainConfirmWindow;
