import Component from './ItemContent';
import { connect } from 'react-redux';
import * as selectors from '../../../../../../../../../../selectors/selectors';
import * as actions from '../../../../../../../../../../actions/actions';

export const mapStateToProps = state => ({
    tableMode: selectors.getTableItemsMode(state),
});

export const mapDispatchToProps = dispatch => ({
    openModalWindow: payload => dispatch(actions.openModalWindow(payload)),
    setTranslateSelectedKey: payload => dispatch(actions.setTranslateSelectedKey(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);