import Component from './HeaderTable';
import { connect } from 'react-redux';
import * as selectors from '../../../../../../selectors/selectors';
import * as actions from '../../../../../../actions/actions';

export const mapStateToProps = state => ({
    languagesData: selectors.getPlatformLanguagesData(state),
    tableItemsMode: selectors.getTableItemsMode(state),
    tableItemsSubMode: selectors.getTableItemsSubMode(state),
});

export const mapDispatchToProps = dispatch => ({
    openModalWindow: payload => dispatch(actions.openModalWindow(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
