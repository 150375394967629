import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
    compose,
    createStore,
    applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getRootReducer } from '../roots/rootReducer';
import rootSaga from '../roots/rootSaga';
import RootComponent from '../modules/rootComponent/index';

const initialState = {
    model: {
        languages: {
            languagesUI: [],
            languagesData: [],
            headerDefaultItems: ['ID', 'Key'],
        },
        tableData: {
            tableMode: 'Admin',
            searchValue: '',
            tableSubMode: 'City',
            tableItemsAdmin: [],
            geoDataTotalPage: 1,
            tableItemsGeoCity: [],
            tableItemsGeoState: [],
            filteredTableItems: [],
            tableAddButtonItem: [{ Key: 'addButton', title: 'addButton', text: 'addButton' }],
            geoDataSelectedPage: 1,
            tableItemsGeoCountry: [],
        },
        translates: {},
        phrasesData: [],
        addNewLanguage: {
            inputs: {
                code: '',
                title: '',
            }
        },
        changeTranslateState: {
            inputValue: '',
            selectedKey: '',
        },
        addNewPhraseInputValue: '',
        isEmptyStringsFilterMode: false,
        theme: {
            icons: {
                logo: require('../../assets/logo.svg').default,
                addBtn: require('../../assets/add.svg').default,
                closeBtn: require('../../assets/close.svg').default,
            }
        }
    },
    modalWindow: {
        isShow: false,
        context: null,
    },
    authUserInfo: {
        token: '',
        userType: '',
        isInputsError: false,
    },
}
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    getRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV === 'development') {
    window.store = store;
}

render(
    <Provider store={store}>
        <RootComponent/>
    </Provider>,
    document.getElementById('root')
)

