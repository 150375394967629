import {
    all,
    call,
} from 'redux-saga/effects';
import modelSaga from '../model/saga';
import authManagerSaga from '../managers/authManager/saga';
import modalManagerSaga from '../managers/modalManager/saga';

export const getSagasList = () => [
    modelSaga,
    authManagerSaga,
    modalManagerSaga,
];

export default function* watchRootSaga() {
    const sagasList = yield call(getSagasList);

    if (!sagasList) {
        console.error("can't create sagas!!");
        return false;
    }

    yield all(sagasList.map(saga => call(saga)));
}