import React, { useEffect } from 'react';
import {
    Input,
    TextValue,
    WrapperMain,
} from './styledComponents';

const DeleteStringValue = props => {
    const {
        context,
    } = props;

    return (
        <WrapperMain>
            <TextValue>
                Delete phrase <span>
                    {context.text}
                </span> ?
            </TextValue>
        </WrapperMain>
    );
};

export default React.memo(DeleteStringValue);