const backDomain = process.env.REACT_APP_BACK_DOMAIN;

const geoUrls = {
  phrasesListGeoCity: `${backDomain}api/translation/city/listCity`,
  phrasesListGeoState: `${backDomain}api/translation/state/listState`,
  phrasesListGeoCountry: `${backDomain}api/translation/country/listCountry`,
  createStringValueGeoCity: `${backDomain}api/translation/city/create`,
  changeStringValueGeoCity: `${backDomain}api/translation/city/edit`,
  changeStringValueGeoState: `${backDomain}api/translation/state/edit`,
  createStringValueGeoState: `${backDomain}api/translation/state/create`,
  createStringValueGeoCountry: `${backDomain}api/translation/country/create`,
  changeStringValueGeoCountry: `${backDomain}api/translation/country/edit`,
};

export default {
  me: `${backDomain}api/me`,
  logIn: `${backDomain}api/auth/login`,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer ",
  },
  addPhrase: `${backDomain}api/translation/phrase/create`,
  addLanguage: `${backDomain}api/translation/language/create`,
  deletePhrase: `${backDomain}api/translation/removePhraseAndTranslations`,
  setCountryVAT: `${backDomain}api/admin/country/setVat`,
  languagesList: `${backDomain}api/translation/language/list`,
  getConfigList: `${backDomain}api/admin/config/list`,
  editConfigList: `${backDomain}api/admin/config/edit`,
  createConfigList: `${backDomain}api/admin/config/create`,
  phrasesListAdmin: `${backDomain}api/translation/phrase/list`,
  translatesByLang: `${backDomain}api/translation/getDictionary`,
  createStringValue: `${backDomain}api/translation/create`,
  changeStringValue: `${backDomain}api/translation/edit`,
  ...geoUrls,
};
