import React from 'react';
import {
    Wrapper,
    ButtonEmpty,
    InputSearch,
} from "./styledComponents";

const Search = props => {
    const {
        searchValue,
        isEmptyStringsFilterMode,
        setStringsSearchInputValue,
        setIsEmptyStringsFilterMode,
    } = props;
    const handleOnChange = event => {
        setStringsSearchInputValue(event.currentTarget.value)
    }

    return (
        <Wrapper>
            <InputSearch
                value={searchValue}
                onChange={handleOnChange}
                placeholder={'Search please...'}
            />
            <ButtonEmpty
                onClick={setIsEmptyStringsFilterMode}
                isChecked={isEmptyStringsFilterMode}
            />
        </Wrapper>
    );
};

export default React.memo(Search);