export default {
  tableModeButtons: [
    { key: "Admin", valueText: "Admin strings" },
    { key: "Geo", valueText: "Geo Strings" },
    { key: "Fee", valueText: "Fee" },
  ],
  tableSubModeButtons: [
    { key: "City", valueText: "City" },
    { key: "State", valueText: "State" },
    { key: "Country", valueText: "Country" },
  ],
};
