import React, { useCallback } from "react";
import { WrapperHeaderTable } from "./styledComponents";
import HeaderTableItem from "./components/headerTableItem/HeaderTableItem";
import AddButtonCustom from "../../../../../customComponents/addButtonCustom/AddButtonCustom";
import { getHeaderTableList } from "./logics";
import { feeHeaderConstantList } from "../../../../../../constants/fee";
const HeaderTable = (props) => {
  const {
    languagesData: { languagesUI },
    openModalWindow,
    tableItemsMode,
    tableItemsSubMode,
  } = props;
  const handleAddButtonClick = useCallback(
    () =>
      openModalWindow({
        type: "confirm",
        subType: "addLanguage",
      }),
    [],
  );
  const headerTableList =
    tableItemsMode !== "Fee"
      ? getHeaderTableList(
          languagesUI,
          tableItemsMode === "Geo" && tableItemsSubMode === "Country",
        )
      : [...feeHeaderConstantList];
  return (
    <WrapperHeaderTable griadColumsRepeatCount={headerTableList.length - 2}>
      {headerTableList.map((item) => (
        <HeaderTableItem key={item} text={item} />
      ))}
      {tableItemsMode !== "Fee" ? (
        <AddButtonCustom handleAddButtonClick={handleAddButtonClick} />
      ) : null}
    </WrapperHeaderTable>
  );
};

export default React.memo(HeaderTable);
