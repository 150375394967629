import styled, { createGlobalStyle } from 'styled-components';
import Inter from '../../../assets/fonts/Inter-Regular.ttf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
  }
  
  body {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    font-family: Inter, sens-serif;
  }

  #root {
    height: 100%;
  }

  .blur-on {
    background: #ffc0cb;
    opacity: 0.4;
    pointer-events: none;
  }

  *::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  *::-webkit-scrollbar-thumb {
    border: 0.06rem solid black;
    background-clip: padding-box;
    border-radius: 0.4375rem;
    background-color: #ff000069;
  }

  *::-webkit-scrollbar-track {
    background: none !important;
  }

  *::-webkit-scrollbar-button {
    width: 0;
    height: 3%;
  }
`;

export const WrapperApp = styled.div`
  width: 100vw;
  min-width: 958px;
  height: 100vh;
  min-height: 768px;
  display: grid;
  grid-template-rows: 44px 1fr;
  grid-row-gap: 21px;
  background: rgb(245, 210, 223);
  background: -webkit-linear-gradient(45deg, rgb(245, 210, 223), rgb(236, 232, 238), rgb(225, 244, 236));
  background: -moz-linear-gradient(45deg, rgb(245, 210, 223), rgb(236, 232, 238), rgb(225, 244, 236));
  background: linear-gradient(45deg, rgb(245, 210, 223), rgb(236, 232, 238), rgb(225, 244, 236));
  padding: 20px;
  box-sizing: border-box;
`;

export const ModalWindowContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
`;