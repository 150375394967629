import React, { useState } from 'react';
import {
    Button,
    Wrapper,
    MainLogin,
    LoginInput,
    HeaderLogin,
    PasswordInput,
    ButtonContainer,
    LogInInputContainer,
    PasswordInputContainer,
} from './styledComponents';

const LogIn = props => {
    const {
        onLogInAction,
        isAuthInputsError,
        setIsAuthInputsError,
    } = props;
    const [inputsValue, setInputsValue] = useState({
        email: '',
        password: '',
    });

    const handleOnLogInButtonClick = () => {
        const {
            email,
            password,
        } = inputsValue;

        if (email && password) {
            onLogInAction(inputsValue)
        }
    }

    const handleOnChange = event => {
        setInputsValue({
            ...inputsValue,
            [event.currentTarget.accessKey]: event.currentTarget.value,
        });

        isAuthInputsError && setIsAuthInputsError(false);
    }

    return (
        <Wrapper>
            <HeaderLogin>Authorization</HeaderLogin>
            <MainLogin>
                <LogInInputContainer>
                    <LoginInput
                        value={inputsValue.email}
                        onChange={handleOnChange}
                        accessKey={'email'}
                        placeholder={'Enter your email...'}
                        isAuthInputsError={isAuthInputsError}
                    />
                </LogInInputContainer>
                <PasswordInputContainer>
                    <PasswordInput
                        type={'password'}
                        value={inputsValue.password}
                        onChange={handleOnChange}
                        accessKey={'password'}
                        placeholder={'Enter your password...'}
                        isAuthInputsError={isAuthInputsError}
                    />
                </PasswordInputContainer>
                <ButtonContainer>
                    <Button onClick={handleOnLogInButtonClick}>LogIn</Button>
                </ButtonContainer>
            </MainLogin>
        </Wrapper>
    );
};

export default React.memo(LogIn);