import keyMirror from "key-mirror";

export const feeConstantData = keyMirror({
  chargebackFee_eur: null,
  chargebackFee_uah: null,
  chargebackFee_kzt: null,
  refundFee_eur: null,
  refundFee_uah: null,
  refundFee_kzt: null,
});

export const feeHeaderConstantData = keyMirror({
  ID: null,
  Key: null,
  EUR: null,
  UAH: null,
  KZT: null,
});

export const feeHeaderConstantList = [
  feeHeaderConstantData.ID,
  feeHeaderConstantData.Key,
  feeHeaderConstantData.EUR,
  feeHeaderConstantData.UAH,
  feeHeaderConstantData.KZT,
];

export const feeTableConstantData = keyMirror({
  refund: null,
  chargeback: null,
});

export const feeTableList = Object.keys(feeTableConstantData).map(
  (el, index) => ({
    [feeHeaderConstantData.ID]: index + 1,
    [feeHeaderConstantData.Key]: el,
    [feeHeaderConstantData.EUR]: "",
    [feeHeaderConstantData.UAH]: "",
    [feeHeaderConstantData.KZT]: "",
  }),
);
