import styled from 'styled-components';

export const WrapperMain = styled.div`
  display: grid;
  grid-template-rows: 1fr 5fr;
`;

export const Input = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid #d9dddd;
  border-radius: 14px;
  box-sizing: border-box;
  font-size: 18px;
  padding: 10px;
  outline: none;
  resize: none;
`;

export const TextValue = styled.div`
  align-self: center;
  padding: 5px 0;

  span {
    font-weight: bold;
    font-size: 20px;
  }
`;