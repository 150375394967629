import { feeTableList, feeHeaderConstantData } from "../constants/fee";

export const getParsedItemData = (
  languagesUI,
  languagesData,
  translates,
  noParsedItemData,
  isGeoMode,
  geoDataSelectedPage,
) => {
  return noParsedItemData.reduce((acc, item, index) => {
    const tableItem = languagesUI.reduce((acc, itemHeader) => {
      let value;

      if (itemHeader === "ID") {
        value = isGeoMode
          ? geoDataSelectedPage * 100 - 100 + index + 1
          : index + 1;
      } else if (itemHeader === "Key") {
        value = item.text;
      } else if (itemHeader === "VAT") {
        if (!item?.vat) {
          return acc;
        }

        value = item.vat;
      } else {
        const languageColumnCode = languagesData.filter(
          (item) => item.title === itemHeader,
        )?.[0]?.code;

        if (isGeoMode) {
          const languageData = item?.localization?.length
            ? item.localization.find(
                (localizationData) =>
                  localizationData.langCode === languageColumnCode,
              )
            : null;
          value = languageData?.value ? languageData.value : "";
        } else {
          value =
            Boolean(languageColumnCode) &&
            translates?.[languageColumnCode] &&
            Array.isArray(translates?.[languageColumnCode])
              ? translates[languageColumnCode]?.filter(
                  (translate) => translate.text === item.text,
                )?.[0]?.translated ?? ""
              : "";
        }
      }

      return {
        ...acc,
        [itemHeader]: String(value),
      };
    }, {});

    return [
      ...acc,
      {
        ...tableItem,
      },
    ];
  }, []);
};

export const getFilteredGeoResponseData = (list, type) =>
  list.map((item) => ({
    id: item.id,
    ...(type === "GeoCountry" ? { vat: item?.vat } : {}),
    text: item?.cityTitle ?? item?.stateTitle ?? item?.countryTitle,
    localization: [...item.localization],
  }));

export const getTranslatesByGeoList = (geoList, languagesData) =>
  geoList.reduce((acc, item) => {
    const localizationData = languagesData.reduce((langAcc, lang) => {
      const itemLocalization =
        item.localization.filter(
          (localizationItem) => localizationItem?.langCode === lang.code,
        )?.[0] ?? {};

      if (langAcc[lang.code] && Object.keys(langAcc[lang.code]).length) {
        return {
          ...langAcc,
          [lang.code]: {
            ...langAcc[lang.code],
            text: item.text,
            phraseId: item.id,
            translated: itemLocalization?.value ?? "",
            translationId: itemLocalization?.id ?? 0,
          },
        };
      } else {
        return {
          ...langAcc,
          [lang.code]: {
            text: item.text,
            phraseId: item.id,
            translated: itemLocalization?.value ?? "",
            translationId: itemLocalization?.id ?? 0,
          },
        };
      }
    }, {});

    const parsedItemLangKeys = Object.keys(localizationData);
    let payload = {
      ...acc,
    };

    for (let lang of parsedItemLangKeys) {
      if (acc[lang] && Object.keys(acc[lang]).length) {
        payload = {
          ...payload,
          [lang]: [
            ...payload[lang],
            {
              ...localizationData[lang],
            },
          ],
        };
      } else {
        payload = {
          ...payload,
          [lang]: [
            {
              ...localizationData[lang],
            },
          ],
        };
      }
    }

    return {
      ...payload,
    };
  }, {});

export const getFeeFilteredList = (feeList, feeConstantData) => {
  return feeList.filter((el) => feeConstantData[el?.["alias"]]);
};

export const getFeeFormattedList = (feeFilteredList) => {
  const feeList = [...feeTableList];
  for (const feeData of feeFilteredList) {
    const [name, currency] = feeData?.["alias"].split("Fee_");
    const findIndex = feeList.findIndex((el) => el.Key === name);

    if (findIndex >= 0) {
      feeList[findIndex] = {
        ...feeList[findIndex],
        [feeHeaderConstantData[currency.toUpperCase()]]:
          feeData?.["value"] ?? "",
      };
    }
  }

  return feeList;
};
