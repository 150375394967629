import React from 'react';
import {
    CloseButton,
    WrapperFooter,
    ConfirmButton,
} from './styledComponents';

const FooterConfirmWindow = props => {
    const {
        onClose,
        context,
        onConfirm,
    } = props;

    return (
        <WrapperFooter>
            <ConfirmButton onClick={onConfirm}>
                {
                    context.subType === 'changeStringValue'
                        ? 'Change'
                        : context.subType === 'deletePhrase'
                            ? 'Delete'
                            :'Create'
                }
            </ConfirmButton>
            <CloseButton onClick={onClose}>
                Cancel
            </CloseButton>
        </WrapperFooter>
    );
};

export default FooterConfirmWindow;