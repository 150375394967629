import {
    put,
    call,
    takeEvery,
} from 'redux-saga/effects';
import ActionTypes from '../../constants/actionTypes';
import * as actions from '../../actions/actions';
import * as selectors from '../../selectors/selectors';
import { sendRestRequest } from '../../api/requestSender';
import urls from '../../constants/urls';
import { parseJSONByDB } from '../../helpers/JSON';

export default function* watchAuthManagerActions() {
    yield takeEvery(ActionTypes.ON_LOG_IN_ACTION, onLogInAction)
};

export function* onLogInAction(action) {
    const {
        email,
        password,
    } = action.payload;
    const { logIn } = urls;
    const requestConfig = {
        url: `${logIn}?password=${password}&email=${email}&udid=AdminPanelAdmin`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            boundary: '011000010111000001101001',
            'Content-Type': 'multipart/form-data',
        },
    }

    const logInResponse = yield call(sendRestRequest, requestConfig);
    const userData = yield call(parseJSONByDB, logInResponse);

    if (logInResponse.isError
        || logInResponse.isNetworkError
        || logInResponse.isTimeoutError
        || !userData
        || !Object.keys(userData).length
        || userData?.data?.user?.userType !== 'admin') {
        yield put(actions.setIsAuthInputsErrorStore(true));

        return false;
    }

    localStorage.setItem('uat', userData.data.token)
    yield put(actions.sendUserInfoResponseStore({
        token: userData.data.token,
        userType: userData.data.user.userType,
    }))
}