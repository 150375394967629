import Component from './AddLanguageMainConfirm';
import { connect } from 'react-redux';
import * as selectors from '../../../../../../../selectors/selectors';
import * as actions from '../../../../../../../actions/actions';

export const mapStateToProps = state => ({
    inputs: selectors.getAddLanguageInputsValue(state),
});

export const mapDispatchToProps = dispatch => ({
    setInputsValue: payload => dispatch(actions.setAddLanguageInputsValue(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);