import ActionTypes from '../../constants/actionTypes';

export default (state, action) => {
    switch(action.type) {
        case ActionTypes.CHANGE_MODAL_WINDOW_STATE:
            return {
                ...action.payload,
            }
        default:
            return { ...state };
    }
}