import ActionTypes from "../constants/actionTypes";

export default (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADD_LANGUAGE_INPUTS_VALUE:
      return {
        ...state,
        addNewLanguage: {
          ...state.addNewLanguage,
          inputs: {
            ...action.payload,
          },
        },
      };
    case ActionTypes.SET_ADD_PARSE_INPUT_VALUE:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.SET_PLATFORM_LANGUAGES_STORE:
      return {
        ...state,
        languages: {
          ...state.languages,
          ...action.payload,
        },
      };
    case ActionTypes.SET_ADMIN_PHRASES_DATA_STORE:
      return {
        ...state,
        phrasesData: [...action.payload],
      };
    case ActionTypes.SET_TRANSLATES_DATA_STORE:
      return {
        ...state,
        translates: { ...action.payload },
      };
    case ActionTypes.SET_TABLE_ITEMS_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          ...action.payload,
        },
      };
    case ActionTypes.SET_TABLE_MODE_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          tableMode: action.payload,
        },
      };
    case ActionTypes.SET_TABLE_SUB_MODE_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          tableSubMode: action.payload,
        },
      };
    case ActionTypes.SET_TRANSLATE_SELECTED_KEY:
      return {
        ...state,
        changeTranslateState: {
          ...state.changeTranslateState,
          selectedKey: action.payload,
        },
      };
    case ActionTypes.SET_TRANSLATE_SELECTED_KEY_INPUT_VALUE:
      return {
        ...state,
        changeTranslateState: {
          ...state.changeTranslateState,
          inputValue: action.payload,
        },
      };
    case ActionTypes.SET_STRINGS_SEARCH_INPUT_VALUE_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          searchValue: action.payload,
        },
      };
    case ActionTypes.SET_GEO_DATA_TOTAL_PAGE_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          geoDataTotalPage: action.payload,
        },
      };
    case ActionTypes.SET_GEO_DATA_SELECTED_PAGE_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          geoDataSelectedPage: action.payload,
        },
      };
    case ActionTypes.SET_FILTERED_TABLE_ITEMS_STORE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          filteredTableItems: action.payload,
        },
      };
    case ActionTypes.SET_IS_EMPTY_STRINGS_FILTER_MODE_STORE:
      return {
        ...state,
        isEmptyStringsFilterMode: !state.isEmptyStringsFilterMode,
      };
    case ActionTypes.SET_FEE_FILTERED_LIST_STORE:
      return {
        ...state,
        feeFilteredList: action.payload,
      };
    default:
      return { ...state };
  }
};
