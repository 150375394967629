import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.top ?? ''};
  left: ${props => props.left ?? ''};
  right: ${props => props.right ?? ''};
  display: flex;
`;

export const ItemButton = styled.div`
  min-width: 30px;
  font-weight: bold;
  width: fit-content;
  background-color: ${props => props.isSelected
                                ? '#ffc0cb42'
                                : ''
  };
  cursor: pointer;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 14px;
  border: 3px solid transparent;
  ${props => props.isSelected
      ? 'border: 3px solid pink;'
      : ''
  };
  
  &:hover {
    text-decoration: underline 3px pink;
  }
`