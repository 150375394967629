import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 75%;
  width: 55%;
  max-width: 550px;
  display: grid;
  grid-template-rows: 40px 1fr;
  background: white;
  border-radius: 40px;
  border: 1px solid #ebe6f8;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
  align-self: center;
  justify-self: center;
`;

export const HeaderLogin = styled.div`
  border-bottom: 1px solid #e1e4e5;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`;

export const MainLogin = styled.div`
  width: 80%;
  height: 50%;
  display: grid;
  grid-template-rows: 1fr 1fr 3fr;
  grid-row-gap: 35px;
  align-self: center;
  justify-self: center;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`;

export const LogInInputContainer = styled(InputContainer)`

`;

export const PasswordInputContainer = styled(InputContainer)`

`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const Button = styled.div`
  height: 46px;
  width: 50%;
  font-weight: bold;
  color: white;
  font-size: 20px;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42c0dc;
  
  &:hover {
    box-shadow: 10px 5px 5px grey;
    transform: scale(110%);
    transition: box-shadow, transform .20s linear;
  }
`;

const Input = styled.input`
  width: 80%;
  height: 100%;
  border: 1px solid ${props => props.isAuthInputsError
                                    ? 'red'
                                    : '#d9dddd'
  };
  border-radius: 14px;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  padding: 10px;
  outline: none;
  justify-self: center;
`;

export const LoginInput = styled(Input)`
  align-self: end;
`;

export const PasswordInput = styled(Input)`

`;