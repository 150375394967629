const getTableModeConstructorName = (state) => {
  return state.model.tableData.tableMode === "Geo"
    ? `${state.model.tableData.tableMode}${state.model.tableData.tableSubMode}`
    : state.model.tableData.tableMode;
};

export const getTheme = (state) => state.model.theme;
export const getTableData = (state) => state.model.tableData;
export const getUserToken = (state) => state.authUserInfo.token;
export const getTableItems = (state) =>
  state.model.tableData[`tableItems${getTableModeConstructorName(state)}`];
export const getTranslates = (state) => state.model.translates;
export const getPhrasesData = (state) => state.model.phrasesData;
export const getAuthUserType = (state) => state.authUserInfo.userType;
export const getModalContext = (state) => state.modalWindow.context;
export const getTranslatesData = (state) => state.model.translates;
export const getTableItemsMode = (state) => state.model.tableData.tableMode;
export const getFeeFilteredList = (state) => state.model.feeFilteredList;
export const getModalIsShowState = (state) => state.modalWindow.isShow;
export const getGeoDataTotalPage = (state) =>
  state.model.tableData.geoDataTotalPage;
export const getIsAuthInputsError = (state) => state.authUserInfo.isInputsError;
export const getTableItemsSubMode = (state) =>
  state.model.tableData.tableSubMode;
export const getAddParseInputValue = (state) =>
  state.model.addNewPhraseInputValue;
export const getFilteredTableItems = (state) =>
  state.model.tableData.filteredTableItems;
export const getGeoDataSelectedPage = (state) =>
  state.model.tableData.geoDataSelectedPage;
export const getChangeTranslateState = (state) =>
  state.model.changeTranslateState;
export const getPlatformLanguagesData = (state) => state.model.languages;
export const getAddLanguageInputsValue = (state) =>
  state.model.addNewLanguage.inputs;
export const getStringsSearchInputValue = (state) =>
  state.model.tableData.searchValue;
export const getIsEmptyStringsFilterMode = (state) =>
  state.model.isEmptyStringsFilterMode;
