import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  background: #ffffff;
  font-weight: bold;
  padding-left: 10px;
  box-sizing: border-box;
`;