import Component from './Search'
import { connect } from 'react-redux';
import * as selectors from '../../../../../../selectors/selectors';
import * as actions from '../../../../../../actions/actions';

export const mapStateToProps = state => ({
    searchValue: selectors.getStringsSearchInputValue(state),
    isEmptyStringsFilterMode: selectors.getIsEmptyStringsFilterMode(state),
})

export const mapDispatchToProps = dispatch => ({
    setStringsSearchInputValue: payload => dispatch(actions.setStringsSearchInputValue(payload)),
    setIsEmptyStringsFilterMode: payload => dispatch(actions.setIsEmptyStringsFilterMode(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component)