import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  ${props => props.isNeedYellow
      ? 'border: 1px solid #ebe6f8'
      : ''
  };
  box-sizing: border-box;
  padding: 6px 6px 6px 10px;
  
  div {
    max-height: 70px;
    overflow: auto;
    z-index: 0 !important;
    
    background: ${props => props.isNeedYellow
        ? 'yellow !important;' +
        'height: 95%;' +
        'width: 98%;'
        : 'white'
    };
  }
`;