import Component from './DeleteStringValue';
import { connect } from 'react-redux';
import * as selectors from '../../../../../../../selectors/selectors';
import * as actions from '../../../../../../../actions/actions';

export const mapStateToProps = state => ({
    changeTranslateState: selectors.getChangeTranslateState(state),
});

export const mapDispatchToProps = dispatch => ({
    deletePhrase: () => {}//payload => dispatch(actions.deletePhrase(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);