import React from "react";
import { ItemWrapper } from "./styledComponents";
import AddButtonCustom from "../../../../../../../../../customComponents/addButtonCustom/AddButtonCustom";

const ItemContent = (props) => {
  const {
    text,
    item,
    tableMode,
    isAddButton,
    openModalWindow,
    itemContentPartKey,
    handleAddButtonClick,
    setTranslateSelectedKey,
  } = props;
  const isNeedBlockKey =
    itemContentPartKey !== "ID" && itemContentPartKey !== "Key";
  const blockKey = isNeedBlockKey
    ? `${item.Key} ${itemContentPartKey} ${item.ID}`
    : itemContentPartKey === "Key"
      ? item.Key
      : "::";
  const handleOnTranslateValueDoubleCLick = (event) => {
    setTranslateSelectedKey(event.currentTarget.accessKey);
    openModalWindow({
      type: "confirm",
      text,
      isVAT: itemContentPartKey === "VAT",
      subType: "changeStringValue",
      ...(tableMode === "Fee" ? { feeData: item } : {}),
    });
  };

  const handleOnPhraseDoubleCLick = (event) => {
    if (tableMode === "Geo" || tableMode === "Fee") {
      return;
    }

    openModalWindow({
      type: "confirm",
      text,
      subType: "deletePhrase",
    });
  };

  const propsToItem = isNeedBlockKey
    ? {
        onDoubleClick: handleOnTranslateValueDoubleCLick,
      }
    : {
        onDoubleClick: handleOnPhraseDoubleCLick,
      };

  return (
    <ItemWrapper
      {...propsToItem}
      accessKey={blockKey}
      isNeedYellow={!Boolean(text) && !isAddButton}
    >
      {props.text === "addButton" ? (
        <AddButtonCustom handleAddButtonClick={handleAddButtonClick} />
      ) : (
        <div>{props.text}</div>
      )}
    </ItemWrapper>
  );
};

export default React.memo(ItemContent);
