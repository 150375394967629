import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 40px 40px 1fr ${props => props.isNeedPagination ? '50px' : ''};
  background: white;
  border-radius: 40px;
  border: 1px solid #ebe6f8;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
`;