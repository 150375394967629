import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e1e4e5;
`;

export const ConfirmTitle = styled.div`
  font-size: 20px;
`;

export const CloseButton = styled.div`
  height: 24px;
  width: 24px;
  background-image: url(${props => props.theme.icons.closeBtn});
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  
  &:hover {
    transform: scale(150%);
    transition: transform .15s linear;
  }
`;