import ActionTypes from '../../constants/actionTypes';

export default (state, action) => {
    switch (action.type) {
        case ActionTypes.SEND_USER_INFO_RESPONSE_STORE:
            return {
                ...state,
                ...action.payload,
            }
        case ActionTypes.SET_IS_AUTH_INPUTS_ERROR_STORE:
            return {
                ...state,
                isInputsError: action.payload,
            }
        default:
            return { ...state };
    }
};