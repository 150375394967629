import React, {
    useRef,
    useState,
} from 'react';
import Scrollbar from 'react-scrollbars-custom';

const handleOnScroll = (event, scrollTop, setScrollTop) => {
    if (event.scrollTop !== scrollTop) {
        setScrollTop(event.scrollTop);
    }
}

function getItems(params) {
    const {
        itemsList,
        scrollTop,
        itemHeight,
        renderItem,
        containerRef,
    } = params;

    const visibleZoneHeight = containerRef?.current?.clientHeight;
    const items = [];
    let topHeight = 0;
    let extraItems = 3;
    let firstIndex = 0;
    let bottomHeight = 0;
    let activeZoneHeight = 0;

    itemsList.forEach((item, index) => {
        if (scrollTop - topHeight > itemHeight * extraItems) {
            topHeight += itemHeight;

            firstIndex = index + 1;
        } else if (index >= firstIndex) {
            if (activeZoneHeight <= visibleZoneHeight + itemHeight * extraItems) {
                items.push(renderItem(item, index));
                activeZoneHeight += itemHeight;

            } else {
                bottomHeight += itemHeight;
            }
        }
    });

    const stubDivTop = <div key={'stabTop'}
                            style={{ height: topHeight }}
                            className='stabTop'
    />;
    const stubDivBottom = <div key={'stab'}
                               style={{ height: bottomHeight }}
                               className='stab'
    />;

    return [
        stubDivTop,
        ...items,
        stubDivBottom,
    ];
}

const VirtualList = props => {
    const scrollRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);

    return (
        <Scrollbar
            ref={scrollRef}
            style={{
                flex: 1,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
            onScroll={event => handleOnScroll(event, scrollTop, setScrollTop)}
        >
            {getItems({ ...props, scrollTop})}
        </Scrollbar>
    );
};

export default React.memo(VirtualList);