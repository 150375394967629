import Component from './MainTable';
import { connect } from 'react-redux';
import * as selectors from '../../../../../../selectors/selectors';
import * as actions from '../../../../../../actions/actions';

export const mapStateToProps = state => ({
    tableItems: selectors.getFilteredTableItems(state),
});

export const mapDispatchToProps = dispatch => ({
    openModalWindow: payload => dispatch(actions.openModalWindow(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);