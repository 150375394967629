import React from 'react';
import { ItemWrapper } from "./styledComponents";

const HeaderTableItem = props => {
    return (
        <ItemWrapper>
            {props.text}
        </ItemWrapper>
    );
};

export default HeaderTableItem;