import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 435px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  left: 0;
`;

export const InputSearch = styled.input`
    height: 40px;
    width: 375px;
    border: 1px solid #d9dddd;
    border-radius: 14px;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    padding: 10px;
    outline: none;
    justify-self: center;
`;

export const ButtonEmpty = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid #d9dddd;
  border-radius: 14px;
  background-color: ${props => props.isChecked
          ? 'yellow'
          : ''
  };

  &:hover {
    background-color: #ffffc2;
  }

  box-sizing: border-box;
  cursor: pointer;
`;