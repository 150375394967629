import styled from 'styled-components';

export const AddButtonCustomWrapper = styled.div`
  width: 24px;
  height: 24px;
  align-self: center;
  justify-self: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.theme.icons.addBtn}) !important;
  cursor: pointer;
  
  &:hover {
    transform: scale(140%);
    transition: transform .15s linear;
  }
`;