export const getArrayPage = (totalPage, selectedPage) => {
    let result = [
        {
            key: 1,
            value: '< prev',
        },
        {
            key: 2,
            value: 1,
        }
    ]

    if (totalPage - 2 > 20) {
        if (selectedPage > 15) {
            result.push(
                {
                    key: result.length + 1,
                    value: '...',
                },
            );

            for (let i = (selectedPage > totalPage - 9 ? selectedPage - (7 + (9 - (totalPage - selectedPage))) : selectedPage - 7); i <= (selectedPage < totalPage - 9 ? selectedPage + 7 : selectedPage); i++ ) {
                result.push(
                    {
                        key: result.length + 1,
                        value: i,
                    },
                )
            }

        } else {
            for (let i = 2; i <= 23; i++) {
                result.push(
                    {
                        key: result.length + 1,
                        value: i,
                    },
                )
            }
        }

        if (selectedPage < totalPage - 9) {
            result.push(
                {
                    key: result.length + 1,
                    value: '...',
                },
            )
        } else {
            if (selectedPage < totalPage) {
                for (let i = selectedPage + 1; i < totalPage; i++) {
                    result.push(
                        {
                            key: result.length + 3,
                            value: i,
                        },
                    )
                }
            }
        }
    } else {
        for (let i = 2; i < totalPage; i++) {
            result.push(
                {
                    key: result.length + Math.random(),
                    value: i,
                }
            )
        }
    }

    if (selectedPage !== totalPage || totalPage - 2 < 20) {
        result.push(
            {
                key: result.length + 100 * Math.random() * Math.random(),
                value: totalPage,
            }
        );
    }

    result.push(
        {
            key: result.length + 200,
            value: 'next >',
        }
    );

    return result;
}