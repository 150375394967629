import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export const Logo = styled.div`
  width: 169px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.theme.icons.logo});
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

Wrapper.logo = Logo;
Wrapper.title = Title;