import Component from './Table';
import { connect } from 'react-redux';
import * as selectors from '../../../../selectors/selectors';
import * as actions from '../../../../actions/actions';

export const mapStateToProps = state => ({
    tableMode: selectors.getTableItemsMode(state),
    tableSubMode: selectors.getTableItemsSubMode(state),
    geoDataTotalPage: selectors.getGeoDataTotalPage(state),
    geoDataSelectedPage: selectors.getGeoDataSelectedPage(state),
});

export const mapDispatchToProps = dispatch => ({
    changeTableMode: payload => dispatch(actions.changeTableMode(payload)),
    changeTableSubMode: payload => dispatch(actions.changeTableSubMode(payload)),
    setGeoDataSelectedPage: payload => dispatch(actions.setGeoDataSelectedPage(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);