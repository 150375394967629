import React from 'react';
import {
    Wrapper,
    ItemButton,
} from './styledComponents';
import moduleConfig from './moduleConfig';

const ModeButtons = props => {
    const {
        top,
        left,
        type,
        right,
        selectedValue,
        onClickAction,
    } = props;

    return (
        <Wrapper
            top={top}
            left={left}
            right={right}
        >
            {
                moduleConfig[`${type}`].map(item => (
                    <ItemButton
                        key={item.key}
                        onClick={() => onClickAction(item.key)}
                        isSelected={item.key === selectedValue}
                    >
                        {item.valueText}
                    </ItemButton>
                ))
            }
        </Wrapper>
    );
};

export default React.memo(ModeButtons);