import React, { useEffect } from 'react';
import {
    WrapperApp,
    GlobalStyle,
    ModalWindowContainer,
} from './styledComponents';
import Header from './components/header/Header';
import Table from './components/table';
import ModalManager from '../../managers/modalManager';
import LogIn from './components/logIn';
import { ThemeProvider } from 'styled-components';

const RootComponent = props => {
    const {
        theme,
        userType,
        initData,
    } = props;
    const isAdmin = userType === 'admin';

    useEffect(() => {
        if (isAdmin) {
            initData();
        }
    }, [isAdmin])

    return (
        <ThemeProvider theme={theme}>
            <WrapperApp>
                <Header/>
                {
                    isAdmin
                        ? <Table/>
                        : <LogIn/>
                }
            </WrapperApp>
            <ModalWindowContainer>
                <ModalManager/>
            </ModalWindowContainer>
            <GlobalStyle/>
        </ThemeProvider>
    );
};

export default React.memo(RootComponent);