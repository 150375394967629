import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 9px;
  justify-content: space-between;
  border-top: 1px solid #d9dddd;
`;

export const NumbersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonSearch = styled.div`
  width: 100px;
  height: 40px;
  border-radius: 14px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc0cb42;
  cursor: pointer;
  margin-left: 10px;
  
  &:hover {
    background: pink;
  }
`;

export const PaginationItem = styled.div`
  ${props => props.isSelectedPage
                ? `
  border: 2px solid pink;
  background-color: #ffc0cb42;`
                : ' border: 2px solid white;'
  }
  ${props => props.isDisabledHover
                ? `opacity: 0.5; cursor: context-menu;`
                : 'cursor: pointer;'
  }
  width: fit-content;
  height: 30px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  padding: ${props => props.isDisabledPadding ? ' ' : '5px'};
  outline: none;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
    background:  #ffc0cb42;
    padding: 0 10px 2px;
  }

  &:last-child {
    margin-right: 10px;
    background:  #ffc0cb42;
    padding: 0 10px 2px;
  }
  
  &:hover {

    ${props => props.isSelectedPage || props.isDisabledHover
            ? ''
            : `
              border: 2px solid pink;
              background-color: #ffc0cb42;`
    }
  }
`;

export const Search = styled.input`
  width: 100px;
  height: 40px;
  border: 1px solid #d9dddd;
  border-radius: 14px;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 10px;
  outline: none;
`;