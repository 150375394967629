import axios from 'axios';

export async function sendRestRequest(config) {
    try {
        return await axios.request(config);
    } catch (error) {
        return {
            error: error,
            isError: true,
            isNetworkError: error?.toString()?.includes('Network Error'),
            isTimeoutError: error?.toString()?.includes('timeout'),
        }
    }
};
