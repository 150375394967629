import React from 'react';
import { AddButtonCustomWrapper } from './styledComponents';

const AddButtonCustom = props => {
    const { handleAddButtonClick } = props;

    return (
        <AddButtonCustomWrapper onClick={handleAddButtonClick}/>
    );
};

export default AddButtonCustom;