import {
    put,
    call,
    takeEvery,
} from 'redux-saga/effects';
import ActionTypes from '../../constants/actionTypes'
import * as actions from '../../actions/actions';
import * as logic from './logic';

export default function* watchModalManagerActions() {
    yield takeEvery(ActionTypes.OPEN_MODAL_WINDOW, openModalWindow)
    yield takeEvery(ActionTypes.ON_CLOSE_MODAL_WINDOW, onCloseModalWindow)
}

export function* openModalWindow({ payload }) {
    yield call(logic.toggleBlurPlatform, true)
    yield put(actions.changeModalWindowState({
        isShow: true,
        context: {
            ...payload,
        }
    }))
}

export function* onCloseModalWindow() {
    yield call(logic.toggleBlurPlatform, false)
    yield put(actions.changeModalWindowState({
        isShow: false,
        context: null
    }))
}