import React from 'react';
import { Wrapper } from './styledComponents';
import HeaderTable from './components/headerTable';
import MainTable from './components/mainTable';
import ModeButtons from './components/modeButtons/ModeButtons';
import Search from "./components/search";
import Pagination from "./components/pagination/Pagination";

const Table = props => {
    const {
        tableMode,
        tableSubMode,
        changeTableMode,
        geoDataTotalPage,
        changeTableSubMode,
        geoDataSelectedPage,
        setGeoDataSelectedPage,
    } = props;
    const isNeedPagination = tableMode === 'Geo';

    return (
        <Wrapper isNeedPagination={isNeedPagination}>
            <ModeButtons
                top={'30px'}
                left={'calc(50% - 130px)'}
                type={'tableModeButtons'}
                selectedValue={tableMode}
                onClickAction={changeTableMode}
            />
            {
                tableMode === 'Geo'
                 ? <ModeButtons
                    top={'107px'}
                    type={'tableSubModeButtons'}
                    right={'40px'}
                    selectedValue={tableSubMode}
                    onClickAction={changeTableSubMode}
                />
                : null
            }
            <Search/>
            <HeaderTable/>
            <MainTable/>

            {
                tableMode === 'Geo'
                    ? <Pagination
                        totalPage={geoDataTotalPage}
                        selectedPage={geoDataSelectedPage}
                        setSelectedPage={setGeoDataSelectedPage}
                    />
                    : null
            }
        </Wrapper>
    );
};

export default React.memo(Table);