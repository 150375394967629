import React, { useCallback } from 'react';
import { ItemWrapper } from './styledComponents';
import ItemContent from './components/itemContent';

const Item = props => {
    const {
        item,
        openModalWindow,
    } = props;
    const itemKeys = Object.keys(item);
    const handleAddButtonClick = useCallback(() => openModalWindow({
        type: 'confirm',
        subType: 'addNewPhrase',
    }), [])

    return (
        <ItemWrapper griadColumsRepeatCount={itemKeys.length - 2}>
            {itemKeys.map(itemKey => {
                const isAddButton = itemKeys.some(itemKey => item[itemKey] === 'addButton')

                return (
                    <ItemContent
                        key={itemKey}
                        item={item}
                        text={item[itemKey]}
                        isAddButton={isAddButton}
                        itemContentPartKey={itemKey}
                        handleAddButtonClick={handleAddButtonClick}
                    />
                );
            })}
        </ItemWrapper>
    );
};

export default React.memo(Item);