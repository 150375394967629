import keyMirror from "key-mirror";

export default keyMirror({
  INIT_DATA: null,
  ON_LOG_IN_ACTION: null,
  CHANGE_TABLE_MODE: null,
  OPEN_MODAL_WINDOW: null,
  ON_CONFIRM_ACTION: null,
  SET_TABLE_MODE_STORE: null,
  CHANGE_TABLE_SUB_MODE: null,
  SET_TABLE_ITEMS_STORE: null,
  ON_CLOSE_MODAL_WINDOW: null,
  SET_TABLE_SUB_MODE_STORE: null,
  SET_ADD_PARSE_INPUT_VALUE: null,
  SET_TRANSLATES_DATA_STORE: null,
  CHANGE_MODAL_WINDOW_STATE: null,
  SET_TRANSLATE_SELECTED_KEY: null,
  SET_GEO_DATA_SELECTED_PAGE: null,
  SET_FEE_FILTERED_LIST_STORE: null,
  SET_ADMIN_PHRASES_DATA_STORE: null,
  SET_PLATFORM_LANGUAGES_STORE: null,
  SET_GEO_DATA_TOTAL_PAGE_STORE: null,
  SEND_USER_INFO_RESPONSE_STORE: null,
  SET_ADD_LANGUAGE_INPUTS_VALUE: null,
  SET_FILTERED_TABLE_ITEMS_STORE: null,
  SET_IS_AUTH_INPUTS_ERROR_STORE: null,
  SET_STRINGS_SEARCH_INPUT_VALUE: null,
  SET_GEO_DATA_SELECTED_PAGE_STORE: null,
  SET_IS_EMPTY_STRINGS_FILTER_MODE: null,
  SET_STRINGS_SEARCH_INPUT_VALUE_STORE: null,
  SET_IS_EMPTY_STRINGS_FILTER_MODE_STORE: null,
  SET_TRANSLATE_SELECTED_KEY_INPUT_VALUE: null,
});
